import 'regenerator-runtime/runtime'

import 'client/styles/main.global.css'
import 'client/styles/stripe.global.css'
import 'client/styles/typography.global.css'

import { useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'
import { Router, useLocation } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import getPageRoute from 'routes/ssr/get-page-route'
import App from 'routes/ssr/App'
import createStore from 'client/lib/create-store'
import { setPageError } from 'client/store/ui/actions'
import isModernBrowser from 'client/lib/is-modern-browser'

(function () {
  const _error = console.error.bind(console)

  console.error = function (...args) {
    if (args?.[0]?.isAxiosError) {
      console.error(args[0].toJSON())
      return
    }

    _error(...args)
  }
})()

export default function ScrollToTop () {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}

export const AppRouter = ({ history, store }) => (
  <Router history={history}>
    <ScrollToTop />
    <App store={store} />
  </Router>
)

const loadPolyfills = async () => {
  if ('ResizeObserver' in window === false) {
    const module = await import('@juggle/resize-observer')
    window.ResizeObserver = module.ResizeObserver
  }
  const isModern = isModernBrowser(navigator.userAgent)

  if (!isModern) {
    await import('core-js/stable')
  }
}

const main = async () => {
  await loadPolyfills()

  const history = createBrowserHistory()
  const { page } = await getPageRoute(history.location.pathname)
  let resolvedPage = page
  if (typeof page._payload?._result === 'function') {
    resolvedPage = await page._payload._result()
  } else if (page._payload) {
    resolvedPage = page._payload._result
  }
  const initialAsyncReducers = resolvedPage.default?.asyncStores || {}

  const initialStoreState = window.__SCITE_STORE_STATE
  const { store } = createStore(initialStoreState, history, initialAsyncReducers)
  history.listen((location, action) => {
    setTimeout(() => {
      const state = store.getState()
      if (state.ui.pageError) {
        store.dispatch(setPageError())
      }
    })
  })

  hydrateRoot(document.getElementById('scite-app'), <AppRouter history={history} store={store} />)
}

window.addEventListener('load', main)
