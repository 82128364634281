import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import * as analytics from 'lib/analytics'
import { highlightText } from 'lib/highlight-text'
import { setSearchFocused } from 'store/ui/actions'
import { selectMaybeDoi } from 'store/ui/selectors'
import styles from './styles/Dropdown.css'

const SearchLink = ({ children, iconClass = styles.linkIcon, ...rest }) => {
  const dispatch = useDispatch()

  return (
    <Link
      data-showdropdown
      data-dropdown-item
      className={styles.searchLink}
      onClick={() => dispatch(setSearchFocused(false))}
      {...rest}
    >
      <i className={iconClass} />
      <span className={styles.itemText}>
        {children}
      </span>
    </Link>
  )
}

export const Dropdown = ({
  term,
  onClickResult,
  dropdownStyle,
  field,
  setDropdownOpen,
  showLinks = false
}) => {
  const autocompletes = useSelector(({ ui }) => ui.search.autocompletes)
  const maybeDoi = useSelector(({ ui }) => selectMaybeDoi(ui))
  const completions = autocompletes[field]
  const hasTerm = term && term.length >= 3

  if (!hasTerm || maybeDoi) {
    return null
  }

  return (
    <div className={classNames(styles.dropdown, dropdownStyle)}>
      {showLinks && (
        <>
          <SearchLink key='in-all' iconClass={styles.searchIcon} to={`/search?mode=all&q=${term}`}>
            Search <b>{term}</b>
          </SearchLink>
          <SearchLink key='in-authors' to={`/search?mode=all&author=${term}`}>
            Search <b>{term}</b> in authors
          </SearchLink>
          <SearchLink key='ask-question' iconClass={styles.questionIcon} to={`/assistant?startTerm=${term}`}>
            Query <b>{term}</b> using assistant
          </SearchLink>
        </>
      )}
      {completions && completions.length > 0 && completions.map((autocomplete, index) => (
        <div
          key={index}
          data-showdropdown
          data-dropdown-item
          tabIndex='0'
          className={styles.dropdownItem}
          onClick={() => {
            analytics.event({
              category: 'Search',
              action: 'Clicked autocomplete result',
              label: autocomplete.completion
            })
            onClickResult(`"${autocomplete.completion}"`)
          }}
        >
          <i className={styles.searchIcon} />
          <span
            className={styles.itemText}
            dangerouslySetInnerHTML={{
              __html: highlightText(
                autocomplete.completion,
                term,
                {
                  startTag: `<span class="${styles.match}">`,
                  endTag: '</span>'
                }
              )
            }}
          />
        </div>
      ))}
    </div>
  )
}

export default Dropdown
