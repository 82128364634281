const IS_DEV = process.env.NODE_ENV === 'development'
const SERVER_SIDE = typeof window === 'undefined'
const DISABLED = (IS_DEV || SERVER_SIDE)

/**
 * @param {string} [email] - Somebody's email.
 */
export function setEmail (email) {
  if (DISABLED) return

  window.$crisp.push(['set', 'user:email', email])
}

/**
 * @param {string} [name] - Somebody's 'nickname'.
 */
export function setName (name) {
  if (DISABLED) return

  window.$crisp.push(['set', 'user:nickname', name])
}

export function setUser (user) {
  const email = user?.email
  const name = user?.name
  if (email) setEmail(email)
  if (name) setName(name)
}

export function setup () {
  if (DISABLED) return

  window.$crisp = []
  window.CRISP_WEBSITE_ID = CONFIG.crisp.id

  const s = document.createElement('script')
  s.src = 'https://client.crisp.chat/l.js'
  s.async = 1
  document.getElementsByTagName('head')[0].appendChild(s)
  window.$crisp.push(['do', 'chat:hide'])
}
