import { detect as detectBrowser } from 'detect-browser'
import createActionConstants from 'lib/create-action-constants'
import * as analytics from 'lib/analytics'

export const MODAL_FROM_CONTEXTS = {
  REFERENCE_CHECK: 'REFERENCE_CHECK',
  FLAG: 'FLAG',
  REPORT_SEARCH: 'REPORT_SEARCH',
  REPORT_FILTER: 'REPORT_FILTER',
  REPORT_DOWNLOAD: 'REPORT_DOWNLOAD',
  REFERENCE_CHECK_DOWNLOAD: 'REFERENCE_CHECK_DOWNLOAD',
  DASHBOARD_DOWNLOAD: 'DASHBOARD_DOWNLOAD',
  DASHBOARD_FILTER: 'DASHBOARD_FILTER',
  VISUALIZATION: 'VISUALIZATION',
  GET_PROFILE: 'GET_PROFILE',
  NOTIFICATION: 'NOTIFICATION',
  CLAIM_PAPER: 'CLAIM_PAPER',
  CREATE_DASHBOARD: 'CREATE_DASHBOARD',
  SAVED_SEARCH: 'SAVED_SEARCH',
  SAVE_CITATION_STATEMENTS_SEARCH: 'SAVE_CITATION_STATEMENTS_SEARCH',
  SEARCH_EXPORT_RESULTS: 'SEARCH_EXPORT_RESULTS',
  CREATE_DASHBOARD_SEARCH_RESULTS: 'CREATE_DASHBOARD_SEARCH_RESULTS',
  SEARCH_FILTER: 'SEARCH_FILTER',
  SEARCH_PAGINATOR: 'SEARCH_PAGINATOR',
  ADD_REPORT_DASHBOARD: 'ADD_REPORT_DASHBOARD',
  REFERENCES: 'REFERENCES',
  CITATION_STATEMENTS_SEARCH: 'CITATION_STATEMENTS_SEARCH',
  CS_SEARCH_PDF_EXPORT: 'CS_SEARCH_PDF_EXPORT',
  ORGANIZATION_LICENSES: 'ORGANIZATION_LICENSES',
  ASSISTANT: 'ASSISTANT',
  DEFAULT: 'DEFAULT'
}

export const constants = createActionConstants('ui', [
  'TOGGLE_MODAL',
  'CLEAR_MODAL_FROM_CONTEXT',
  'TOGGLE_NAV',
  'TOGGLE_MOBILE_SEARCH',
  'SET_USER',
  'SET_PAGE_ERROR',

  'LOAD_BROWSER',
  'LOAD_BROWSER_FAILURE',

  'HANDLE_REFERRAL',

  'DOCK_FREE_REPORT',

  'LOAD_USER',
  'LOAD_USER_SUCCESS',
  'LOAD_USER_FAILURE',

  'FETCH_GEOLOCATION',
  'FETCH_GEOLOCATION_SUCCESS',
  'FETCH_GEOLOCATION_FAILURE',

  'SAVE_USER',
  'SAVE_USER_SUCCESS',
  'SAVE_USER_FAILURE',

  'MERGE_USER_SUBSCRIPTION',

  'LOGOUT',
  'LOGOUT_SUCCESS',
  'LOGOUT_FAILURE',

  'GET_ENTITLEMENTS',
  'GET_ENTITLEMENTS_SUCCESS',
  'GET_ENTITLEMENTS_FAILURE',

  'SAVE_REFERRALS',
  'SAVE_REFERRALS_SUCCESS',
  'SAVE_REFERRALS_FAILURE',

  'GET_NEW_TOKEN',
  'LOAD_ANON_TOKEN',

  'EXTENSION_LOADED',

  'SET_BANNER_CONTENT',
  'SET_BANNER_CLOSED',

  'CHECK_PREMIUM_IP',
  'CHECK_PREMIUM_IP_SUCCESS',
  'CHECK_PREMIUM_IP_FAILURE',

  'DELETE_USER',
  'DELETE_USER_SUCCESS',
  'DELETE_USER_FAILURE',

  'COMPLETE_ONBOARDING_STEP',
  'COMPLETE_ONBOARDING_FAILURE',

  'ADD_TO_ORGANIZATION_CONTACTS',

  'REDEEM_CODE',
  'REDEEM_CODE_ERROR',
  'REDEEM_CODE_SUCCESS',

  'APPLY_DISCOUNT',
  'APPLY_DISCOUNT_ERROR',
  'APPLY_DISCOUNT_SUCCESS',

  'FETCH_DISCOUNT',
  'FETCH_DISCOUNT_ERROR',
  'FETCH_DISCOUNT_SUCCESS',

  'SET_HAS_USER',

  'SET_SEARCH_TERM',
  'SET_SEARCH_FOCUSED',

  'FETCH_AUTOCOMPLETES',
  'FETCH_AUTOCOMPLETES_SUCCESS',
  'FETCH_AUTOCOMPLETES_FAILURE',
  'FLUSH_AUTOCOMPLETES',

  'FETCH_ONBOARDING',
  'FETCH_ONBOARDING_SUCCESS',
  'FETCH_ONBOARDING_FAILURE',
  'FLUSH_ONBOARDING_LOADED',

  'SET_REFERENCES_TO_EXPORT',

  'SET_CITE_GENERATOR_PAPER_DATA',

  'EXPORT_REFERENCES',
  'EXPORT_REFERENCES_SUCCESS',
  'EXPORT_REFERENCES_FAILURE',

  'UPDATE_DIMENSIONS',

  'TRIAL_ACTIVATED',
  'TRIAL_ACTIVATED_SUCCESS',
  'TRIAL_ACTIVATED_FAILURE',

  'LOAD_COOKIE_PREFERENCE',
  'SET_COOKIE_PREFERENCE',

  'FETCH_CHURN_KEY',
  'FETCH_CHURN_KEY_SUCCESS',
  'FETCH_CHURN_KEY_FAILURE',

  'FETCH_FULL_TEXT_LINK',
  'FETCH_FULL_TEXT_LINK_SUCCESS',
  'FETCH_FULL_TEXT_LINK_FAILURE',

  'FETCH_AG_CHECK_AVAILABILITY_DETAILS',
  'FETCH_AG_CHECK_AVAILABILITY_DETAILS_SUCCESS',
  'FETCH_AG_CHECK_AVAILABILITY_DETAILS_FAILURE',

  'UPDATE_ACCESS_LOG'
])

export const toggleModal = (name, to, from) => ({
  type: constants.TOGGLE_MODAL,
  payload: {
    name, to, from
  }
})

export const toggleNav = (toggleNav) => ({
  type: constants.TOGGLE_NAV,
  payload: toggleNav
})

export const setUser = user => {
  analytics.setUser(user)
  return {
    type: constants.SET_USER,
    payload: user
  }
}

export const saveUser = user => {
  analytics.setUser(user)
  return {
    type: constants.SAVE_USER,
    payload: user
  }
}

export const loadUser = (opts = {}) => ({
  type: constants.LOAD_USER,
  payload: opts
})

export const deleteUser = (history) => ({
  type: constants.DELETE_USER,
  payload: { history }
})

export const logout = () => {
  return {
    type: constants.LOGOUT
  }
}

export const loadBrowser = browser => {
  if (typeof window === 'undefined') {
    return {
      type: constants.LOAD_BROWSER,
      payload: null
    }
  }

  const detected = detectBrowser()
  if (!detected) {
    return {
      type: constants.LOAD_BROWSER_FAILURE
    }
  }

  return {
    type: constants.LOAD_BROWSER,
    payload: {
      name: detected.name,
      os: detected.os,
      version: detected.version
    }
  }
}

export const handleReferral = referrer => ({
  type: constants.HANDLE_REFERRAL,
  payload: referrer || document.referrer
})

export const dockFreeReport = () => ({
  type: constants.DOCK_FREE_REPORT
})

export const setPageError = pageError => ({
  type: constants.SET_PAGE_ERROR,
  payload: pageError
})

export const getEntitlements = () => ({
  type: constants.GET_ENTITLEMENTS
})

export const saveReferrals = referrals => ({
  type: constants.SAVE_REFERRALS,
  payload: referrals
})

export const getNewToken = () => ({
  type: constants.GET_NEW_TOKEN
})

export const loadAnonToken = token => ({
  type: constants.LOAD_ANON_TOKEN,
  payload: token
})

export const extensionLoaded = () => ({
  type: constants.EXTENSION_LOADED
})

export const setBannerContent = bannerContent => ({
  type: constants.SET_BANNER_CONTENT,
  payload: bannerContent
})

export const setBannerClosed = val => ({
  type: constants.SET_BANNER_CLOSED,
  payload: val
})

export const checkOrganizationLicense = () => ({
  type: constants.CHECK_PREMIUM_IP
})

export const completeOnboardingStep = ({ category, step, email }) => ({
  type: constants.COMPLETE_ONBOARDING_STEP,
  payload: {
    category,
    step,
    email
  }
})

export const toggleMobileSearch = () => ({ type: constants.TOGGLE_MOBILE_SEARCH })

export const redeemCode = (code) => ({
  type: constants.REDEEM_CODE,
  payload: code
})

export const applyDiscount = (payload) => ({
  type: constants.APPLY_DISCOUNT,
  payload
})

export const fetchDiscount = (payload) => ({
  type: constants.FETCH_DISCOUNT,
  payload
})

export const setHasUser = (hasUser) => ({
  type: constants.SET_HAS_USER,
  payload: hasUser
})

export const setSearchFocused = focused => ({
  type: constants.SET_SEARCH_FOCUSED,
  payload: focused
})

export const setSearchTerm = term => ({
  type: constants.SET_SEARCH_TERM,
  payload: term
})

export const fetchAutocompletes = (term, field, { includeFields = [] } = {}) => ({
  type: constants.FETCH_AUTOCOMPLETES,
  payload: { term, field, includeFields }
})

export const flushAutocompletes = field => ({
  type: constants.FLUSH_AUTOCOMPLETES,
  payload: field
})

export const fetchOnboarding = (slug) => ({
  type: constants.FETCH_ONBOARDING,
  payload: slug
})

export const flushOnboardingLoaded = () => ({
  type: constants.FLUSH_ONBOARDING_LOADED
})

export const setReferencesToExport = dois => ({
  type: constants.SET_REFERENCES_TO_EXPORT,
  payload: dois
})

export const setCiteGeneratorPaperData = ({ citeGeneratorPaperData }) => ({
  type: constants.SET_CITE_GENERATOR_PAPER_DATA,
  payload: {
    citeGeneratorPaperData
  }
})

export const exportReferences = params => ({
  type: constants.EXPORT_REFERENCES,
  payload: {
    params
  }
})

export const updateDimensions = (name, dimensions) => ({
  type: constants.UPDATE_DIMENSIONS,
  payload: { name, dimensions }
})

export const markTrialActivated = () => ({
  type: constants.TRIAL_ACTIVATED
})

export const loadCookiePreference = (isDoNotSell = false) => ({
  type: constants.LOAD_COOKIE_PREFERENCE,
  payload: { isDoNotSell }
})

export const fetchChurnKey = () => ({
  type: constants.FETCH_CHURN_KEY
})

export const fetchFullTextLink = (doi, memberId) => ({
  type: constants.FETCH_FULL_TEXT_LINK,
  payload: {
    doi, memberId
  }
})

export const fetchAGCheckAvailabilityDetails = (doi) => ({
  type: constants.FETCH_AG_CHECK_AVAILABILITY_DETAILS,
  payload: {
    doi
  }
})

export const updateAccessLog = (doi, url) => ({
  type: constants.UPDATE_ACCESS_LOG,
  payload: {
    doi,
    url
  }
})

export const fetchGeolocation = () => ({
  type: constants.FETCH_GEOLOCATION
})
