import createActionConstants from 'lib/create-action-constants'

export const constants = createActionConstants('ui', [
  'SET_MODE',
  'SET_RESET_PASSWORD_TOKEN',
  'TOGGLE_PRIVACY_AGREED',
  'SET_FIELD',

  'SUBMIT',
  'SUBMIT_SUCCESS',
  'SUBMIT_FAILURE',

  'FETCH_SIGNUP_TYPE',
  'FETCH_SIGNUP_TYPE_SUCCESS',
  'FETCH_SIGNUP_TYPE_FAILURE',
  'FLUSH_SIGNUP_TYPE',

  'SET_USER_SIGNUP_TYPE',
  'SET_USER_SIGNUP_ORG'
])

export const setMode = mode => ({
  type: constants.SET_MODE,
  payload: mode
})

export const togglePrivacyAgreed = () => ({
  type: constants.TOGGLE_PRIVACY_AGREED
})

export const setField = (field, value, error) => ({
  type: constants.SET_FIELD,
  payload: {
    field, value
  },
  error
})

export const submit = (mode, fields, history) => ({
  type: constants.SUBMIT,
  payload: {
    mode, fields, history
  }
})

export const fetchSignupType = (email) => ({
  type: constants.FETCH_SIGNUP_TYPE,
  payload: {
    email
  }
})

export const flushSignupType = () => ({
  type: constants.FLUSH_SIGNUP_TYPE
})

export const setUserSignupType = (signupType) => ({
  type: constants.FETCH_SIGNUP_TYPE_SUCCESS,
  payload: {
    signupType
  }
})

export const setUserSignupTypeOrg = (signupOrg) => ({
  type: constants.FETCH_SIGNUP_TYPE_SUCCESS,
  payload: {
    signupOrg
  }
})
