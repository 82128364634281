const ENABLED = CONFIG.rollbar.enabled

const _guard = fn => (...args) => {
  if (process.env.NODE_ENV === 'test') {
    return
  }

  if (!ENABLED) {
    console.log('Rollbar disabled due to config')
    return
  }

  if (typeof Rollbar === 'undefined') {
    console.log('Rollbar instance not found')
    return
  }

  return fn(...args)
}

export const setup = _guard(() => {
  Rollbar.configure({
    ENABLED,
    hostSafeList: CONFIG.rollbar.scriptDomains,
    ignoredMessages: CONFIG.rollbar.ignoredMessages,
    checkIgnore: (isUncaught, args, payload) => {
      if (!window.navigator.userAgent) {
        return false
      }

      for (const bannedStr of CONFIG.rollbar.bannedUAs) {
        if (window.navigator.userAgent.indexOf(bannedStr) !== '-1') {
          return true
        }
      }

      return false
    }
  })
  Rollbar.global({
    itemsPerMinute: CONFIG.rollbar.itemsPerMinute
  })
})

export const setUser = _guard(user => {
  Rollbar.configure({
    payload: {
      person: {
        id: user.slug,
        username: user.name,
        email: user.email
      }
    }
  })
})

export const handlePageChange = _guard(location => {
  Rollbar.configure({
    payload: {
      context: location.pathname
    }
  })
})
