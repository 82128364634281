import Paragraph from '../typography/Paragraph'

import styles from './styles/Feature.css'
import Header from '../typography/Header'
import Link from '../misc/Link'
import classNames from 'classnames'
import * as analytics from 'lib/analytics'

export const Feature = ({ img = '', imgTop = false, imgClass = '', to = '', href = '', header, children, className, ...rest }) => {
  const onFeatureDropdownClick = (header) => {
    analytics.event({
      category: 'Feature Dropdown',
      action: header
    })
  }

  return (
    <Link to={to} href={href} {...rest} onClick={() => onFeatureDropdownClick(header)}>
      {(imgTop && img) && <img className={classNames(styles.illo, styles.centered, imgClass)} src={img} alt={header} />}
      <div className={classNames(styles.feature, className)}>
        {(!imgTop && img) && <img className={classNames(styles.illo, imgClass)} src={img} alt={header} />}
        <div className={styles.content}>

          <Header className={imgTop ? styles.headerLarge : styles.header} level={6}>
            {header}
          </Header>
          {children && (
            <Paragraph light type='bodyLeft' className={imgTop ? styles.featureCopyLarge : styles.featureCopy}>
              {children}
            </Paragraph>
          )}
        </div>
      </div>
    </Link>
  )
}

export default Feature
