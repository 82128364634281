import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './styles/Button.css'

export function Button ({ className, children, color = 'transparent', pill, group, row, bold, small, tiny, ...rest }) {
  const classes = classNames(className, styles.btn, styles[`btn--${color}`], {
    [styles.pill]: pill,
    [styles.group]: group,
    [styles.row]: row,
    [styles.bold]: bold,
    [styles.small]: small,
    [styles.tiny]: tiny
  })

  return (
    <button className={classes} {...rest}>
      {children}
    </button>
  )
}

Button.propTypes = {
  className: PropTypes.string,
  color: PropTypes.oneOf(['blank', 'red', 'blue', 'white', 'transparent']),
  pill: PropTypes.bool,
  group: PropTypes.bool,
  row: PropTypes.bool,
  bold: PropTypes.bool,
  small: PropTypes.bool,
  tiny: PropTypes.bool
}

export default Button
