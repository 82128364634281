import OpenerIcon from 'components/misc/OpenerIcon'

import { Fade } from 'components/misc/Fade'
import styles from './styles/HoverLink.css'
import Link from '../misc/Link'

const HoverLink = ({
  children,
  to = null,
  includeArrow = false,
  open = false,
  ...rest
}) => (
  <div
    className={styles.container}
    {...rest}
  >
    <Link to={to} className={styles.link} role='button'>
      {children}
    </Link>
    {includeArrow && (
      <>
        <OpenerIcon className={styles.icon} show={open} />
        <Fade className={styles.arrow} show={open} />
      </>
    )}
  </div>
)

export default HoverLink
