import React, { useState, useEffect, useRef, useCallback } from 'react'
import useScroll from 'hooks/useScroll'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { Link as ExternalLink } from 'components/misc/Link'
import { Fade } from 'components/misc/Fade'
import useClickOut from 'hooks/useClickOut'
import SearchBox from 'components/search/SearchBox'
import NavButtons from 'components/navbar/NavButtons'
import Measure from 'components/misc/Measure'
import { Slide } from 'components/misc/Slide'
import { navSubmit } from 'store/search/actions'
import { completeOnboardingStep, setSearchFocused, toggleMobileSearch } from 'store/ui/actions'

import styles from './styles/NavBar.css'
import classNames from 'classnames'
import Banner from '../banner/Banner'
import Header from '../typography/Header'

const SEARCH_PLACEHOLDER = 'Search by title, author, keywords or DOI'

//
// TODO:
//       Use of this hard coded breakpoint is kind of a hack...
//
const LARGE_BREAKPOINT = 60 * 16
const MEDIUM_BREAKPOINT = 30 * 16

const MobileSearch = () => {
  const dispatch = useDispatch()
  const input = useRef(null)
  const [mounted, setMounted] = useState(false)
  const showMobileSearch = useSelector(({ ui }) => ui.showMobileSearch)

  useEffect(() => {
    setMounted(true)
  }, [])

  useEffect(() => {
    if (mounted && input.current && showMobileSearch) {
      input.current.focus()
    }
  }, [showMobileSearch])

  return (
    <Fade className={styles.mobileSearch} show={showMobileSearch} data-testid='mobile-search-box'>
      <SearchBox
        className={styles.mobileSearchBox}
        inputClass={styles.mobileSearchInput}
        dropdownClass={styles.mobileDropdownClass}
        placeholder={SEARCH_PLACEHOLDER}
        onSubmit={e => {
          dispatch(navSubmit())
          dispatch(toggleMobileSearch())
        }}
        inputRef={input}
        dropdown
      />
      <i
        className={styles.mobileSearchCloseIcon}
        role='button'
        tabIndex='0'
        aria-label='Close search'
        onClick={_ => dispatch(toggleMobileSearch())}
      />
    </Fade>
  )
}

function NavBarBody ({ className, staticPage = false, homePage = false, sticky = false, spacer = false, assistant = false, setChatHistoryOpen, chatHistoryOpen }) {
  const ref = useRef()
  const location = useLocation()
  const dispatch = useDispatch()
  const input = useRef(null)
  const hasUser = useSelector(({ ui }) => ui.hasUser)
  const user = useSelector(({ ui }) => ui.user)
  const extensionLoaded = useSelector(({ ui }) => ui.extensionLoaded)
  const focused = useSelector(({ ui }) => ui.search.focused)
  const large = typeof window === 'undefined' ? true : window.innerWidth >= LARGE_BREAKPOINT
  const medium = typeof window === 'undefined' ? false : window.innerWidth >= MEDIUM_BREAKPOINT
  const [show, setShow] = useState(true)

  const handleScroll = useCallback(({ lastKnownY }) => {
    const currentY = window.scrollY

    if (currentY > lastKnownY && currentY > 50) {
      setShow(false)
    } else {
      setShow(true)
    }
  }, [setShow])

  useScroll(handleScroll)

  useClickOut(ref, focused, () => {
    if (spacer) {
      return
    }

    dispatch(setSearchFocused(false))
  })

  useEffect(() => {
    if (user && user.onboarding && !user.onboarding.extension?.initialOnboarding && extensionLoaded) {
      dispatch(completeOnboardingStep({
        category: 'extension',
        step: 'initial_onboarding',
        email: user.email
      }))
    }
  }, [user, extensionLoaded])

  return (
    <nav
      ref={ref}
      className={classNames(styles.navBarContainer, {
        [styles.sticky]: sticky,
        [styles.spacer]: spacer
      })}
      /* For tests where there is no CSS */
      style={spacer ? { visibility: 'hidden' } : {}}
    >
      <Slide
        className={classNames(styles.navBar, {
          [className]: className,
          [styles.transparent]: staticPage,
          [styles.notStatic]: !staticPage,
          [styles.spacer]: spacer
        })}
        show={large || show || focused || spacer || (sticky && medium)}
      >
        <div className={styles.navContents}>
          {!assistant && (
            <Link className={styles.logo} to={hasUser ? '/home' : '/'}>
              <img
                alt='scite logo'
                width='115px'
                height='38px'
                className={styles.logoDesktop}
                src={homePage ? `${CONFIG.assetsBaseURL}/images/logo-blue.svg` : `${CONFIG.assetsBaseURL}/images/logo.svg`}
              />
              <img
                alt='scite logo'
                width='64px'
                height='38px'
                className={styles.logoMobile}
                src={homePage ? `${CONFIG.assetsBaseURL}/images/logo-blue.svg` : `${CONFIG.assetsBaseURL}/images/logo.svg`}
              />
            </Link>
          )}
          {assistant && (
            <div className={styles.assistantNavIdentifier}>
              <div tabIndex={0} className={styles.assistantHamburgerMenu} onClick={() => setChatHistoryOpen(!chatHistoryOpen)}>
                <i className={styles.assistantHamburgerMenuIcon} />
              </div>
              <ExternalLink href={`${CONFIG.sciteFrontendURL}/assistant`} className={styles.assistantLink}>
                <Header className={styles.assistantNavHeader} level={6}>assistant</Header>
              </ExternalLink>
              <span className={styles.by}>by</span>
              <ExternalLink to='/home' className={styles.assistantLogoLink}>
                <Header className={styles.assistantNavHeader} level={6}>scite</Header>
              </ExternalLink>
            </div>
          )}
          {!assistant && location.pathname !== '/search' && (
            <>
              <SearchBox
                className={classNames(styles.searchBox, {
                  [styles.staticSearchContainer]: staticPage
                })}
                inputClass={styles.searchInput}
                buttonClass={classNames(styles.searchButton, {
                  [styles.staticSearchButton]: staticPage
                })}
                buttonIconClass={styles.searchButtonIcon}
                inputRef={input}
                onSubmit={e => {
                  dispatch(navSubmit())
                }}
                placeholder={SEARCH_PLACEHOLDER}
                dropdown
              />
              <i
                className={styles.mobileSearchIcon}
                role='button'
                tabIndex='0'
                aria-label='Search'
                onClick={_ => dispatch(toggleMobileSearch())}
              />
            </>
          )}
          <div className={styles.rightContent}>
            <div className={styles.navButtonsLayout}>
              <NavButtons buttonColor='blue' staticPage={staticPage || !user?.email} assistant={assistant} avatarBg={staticPage ? 'white' : 'gray'} />
            </div>
          </div>
        </div>

        {location.pathname !== '/search' && (

          <MobileSearch />
        )}
      </Slide>
    </nav>
  )
}

export const NavBar = (props) => (
  <div>
    <Banner />
    <NavBarBody {...props} />
    <Measure name='nav'>
      <NavBarBody spacer />
    </Measure>
  </div>
)

export default NavBar
