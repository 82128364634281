import createActionConstants from 'lib/create-action-constants'

export const constants = createActionConstants('assistant', [
  'FETCH_ASSISTANT',
  'FETCH_ASSISTANT_SUCCESS',
  'FETCH_ASSISTANT_FAILURE',
  'FETCH_ASSISTANT_TIMEOUT',
  'FETCH_ASSISTANT_CANCELLED',
  'FETCH_ASSISTANT_CANCEL',
  'FETCH_ASSISTANT_UPGRADE_REQUIRED',

  'FETCH_ASSISTANT_SESSIONS',
  'FETCH_ASSISTANT_SESSIONS_SUCCESS',
  'FETCH_ASSISTANT_SESSIONS_FAILURE',

  'SWITCH_SESSION',
  'SWITCH_SESSION_SUCCESS',
  'SWITCH_SESSION_FAILURE',

  'DELETE_ASSISTANT_SESSIONS',
  'DELETE_ASSISTANT_SESSIONS_SUCCESS',
  'DELETE_ASSISTANT_SESSIONS_FAILURE',

  'DOWNLOAD_ASSISTANT_REFERENCES',
  'DOWNLOAD_ASSISTANT_REFERENCES_SUCCESS',
  'DOWNLOAD_ASSISTANT_REFERENCES_FAILURE',

  'DOWNLOAD_ASSISTANT_TRANSCRIPT',
  'DOWNLOAD_ASSISTANT_TRANSCRIPT_SUCCESS',
  'DOWNLOAD_ASSISTANT_TRANSCRIPT_FAILURE',

  'NEW_SESSION',

  'LOAD_ASSISTANT_SETTINGS',
  'SET_ASSISTANT_SETTINGS',

  'SET_FEEDBACK',
  'SET_FEEDBACK_SUCCESS',
  'SET_FEEDBACK_FAILURE',

  'ASSISTANT_PROGRESS',

  'SET_CURRENT_TURN',

  'ASSISTANT_UPDATE_USAGE'
])

export const assistantUpdateUsage = (payload) => ({
  type: constants.ASSISTANT_UPDATE_USAGE,
  payload
})

export const switchSession = (payload) => ({
  type: constants.SWITCH_SESSION,
  payload
})

export const deleteAssistantSessions = (payload) => ({
  type: constants.DELETE_ASSISTANT_SESSIONS,
  payload
})

export const cancelFetchAssistant = (taskId) => ({
  type: constants.FETCH_ASSISTANT_CANCEL,
  payload: taskId
})

export const fetchAssistant = (payload, slicePreviousTurnsTo = undefined) => ({
  type: constants.FETCH_ASSISTANT,
  payload: {
    ...payload,
    slicePreviousTurnsTo
  }
})

export const fetchAssistantSessions = (payload) => ({
  type: constants.FETCH_ASSISTANT_SESSIONS,
  payload
})

export const newSession = () => ({
  type: constants.NEW_SESSION
})

export const downloadReferences = (payload) => ({
  type: constants.DOWNLOAD_ASSISTANT_REFERENCES,
  payload
})

export const downloadTranscript = (payload) => ({
  type: constants.DOWNLOAD_ASSISTANT_TRANSCRIPT,
  payload
})

export const loadAssistantSettings = () => ({
  type: constants.LOAD_ASSISTANT_SETTINGS
})

export const setAssistantSettings = (payload) => ({
  type: constants.SET_ASSISTANT_SETTINGS,
  payload
})

export const setFeedback = (payload) => ({
  type: constants.SET_FEEDBACK,
  payload
})

export const setCurrentTurn = (payload) => ({
  type: constants.SET_CURRENT_TURN,
  payload
})
