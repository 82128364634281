import { useRef } from 'react'

import { Fade } from 'components/misc/Fade'
import useClickOut from 'hooks/useClickOut'
import styles from './styles/Dropdown.css'
import HoverLink from './HoverLink'

export const Dropdown = ({ open, setOpen, title, children }) => {
  const ref = useRef(null)

  useClickOut(ref, open, () => setOpen(false))

  return (
    <div ref={ref}>
      <HoverLink
        open={open}
        onClick={e => setOpen(!open)}
        includeArrow
      >{title}
      </HoverLink>
      <Fade
        className={styles.dropdown}
        show={open}
      >
        <div>
          {children}
        </div>
      </Fade>
    </div>
  )
}

export default Dropdown
