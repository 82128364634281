const PLANS = {
  free: 'free',
  premium: 'premium',
  organizational: 'organizational'
}

const SUBSCRIPTION_STATUS = {
  inactive: 'inactive',
  active: 'active',
  trialing: 'trialing',
  paymentFailed: 'payment_failed',
  paused: 'paused'
}

const STRIPE_STATUS_TO_SCITE_STATUS = {
  active: SUBSCRIPTION_STATUS.active,
  trialing: SUBSCRIPTION_STATUS.trialing,
  canceled: SUBSCRIPTION_STATUS.inactive
}

module.exports = {
  PLANS,
  SUBSCRIPTION_STATUS,
  STRIPE_STATUS_TO_SCITE_STATUS
}
