import createReducer from 'lib/create-reducer'
import * as analytics from 'lib/analytics'
import { constants } from './actions'
import { constants as searchConstants } from 'store/search/actions'
import saga from './sagas'
import { getLocalStorage } from '../../lib/get-local-storage'

export const defaultState = {
  referralPartner: null,
  freeReports: 0,

  bannerContent: null,
  bannerClosed: false,

  navMenu: {},

  createDashboardFromSearchResultsModalShow: false,
  savedSearchModalShow: false,
  searchFilterModalShow: false,
  flagModalShow: false,
  resetPasswordModalShow: false,
  loginModalShow: false,
  referenceCheckModalShow: false,
  deleteDashboardModalShow: false,
  addReportToDashboardModalShow: false,
  shareDashboardModalShow: false,
  editDashboardModalShow: false,
  upgradeModalShow: false,
  exportReportModalShow: false,
  exportReferenceModalShow: false,
  generateCitationModalShow: false,
  exportDashboardModalShow: false,
  dashboardLimitErrorModalShow: false,
  whatIsThisReportModalShow: false,
  whatIsThisSearchModalShow: false,
  whatIsAssistantModalShow: false,
  trialOverModalShow: false,
  activateTrialModalShow: false,
  cancelTrialCheckoutModalShow: false,
  cookieModalShow: false,
  personaModalShow: false,
  modalFromContext: '',
  shownModals: [],

  hasUser: false,
  user: null,
  ipLocationData: {
    data: null,
    error: false,
    loading: false
  },
  fetchingUser: false,
  savingUser: false,
  saveUserError: false,

  onboardingLoadTracker: {
    state: undefined
  },

  userEntitlements: null,
  entitlementsLoading: false,

  referralsLoading: false,

  pageError: null,

  browser: null,
  extensionLoaded: false,

  organizationLicense: {
    hasLicense: false
  },
  linkResolver: null,

  sickoMode: false,

  authors: [],

  navOpen: false,

  showMobileSearch: false,

  redeemCodeLoading: false,
  redeemCodeError: null,
  redeemCodeSuccess: false,

  applyDiscountLoading: false,
  applyDiscountError: null,
  applyDiscountSuccess: false,

  userDiscount: null,

  search: {
    term: '',
    focused: false,
    autocompletesLoadTracker: {},
    autocompletes: {}
  },

  referencesToExport: [],

  citeGeneratorPaperData: {},

  dimensions: {},

  cookiePreference: null,

  userChurnKey: null,
  churnKeyLoading: false,

  fullTextLinkState: {},

  agAvailabilityState: {}
}

const addToFullTextLinkStateQueue = (fullTextLinkState, newLinkState, doi, queueSize = 99) => {
  const keys = Object.keys(fullTextLinkState)
  if (keys.length >= queueSize) {
    // only keep the last 99 dois
    const newFullTextLinkState = {}
    keys.slice(keys.length - queueSize).forEach(key => {
      newFullTextLinkState[key] = fullTextLinkState[key]
    })
    return {
      ...newFullTextLinkState,
      [doi]: newLinkState
    }
  }
  return {
    ...fullTextLinkState,
    [doi]: newLinkState
  }
}

const addToAGAvailabilityStateQueue = (agAvailabilityState, newAvailabilityState, doi, queueSize = 99) => {
  const keys = Object.keys(agAvailabilityState)
  if (keys.length >= queueSize) {
    delete agAvailabilityState[keys[0]]
  }
  return {
    ...agAvailabilityState,
    [doi]: newAvailabilityState
  }
}

export const ui = createReducer(defaultState, {
  [constants.TOGGLE_MODAL]: (state, { name, to, from }) => {
    const { shownModals } = state
    const key = `${name}ModalShow`
    if (!(key in state)) {
      console.warn('Tried to toggle non-existant modal!')
      return state
    }

    const toggleTo = typeof to === 'boolean' ? to : !state[key]
    if (toggleTo) {
      analytics.modalView(name)
    }
    return {
      ...state,
      [key]: toggleTo,
      modalFromContext: from || state.modalFromContext,
      shownModals: toggleTo && !shownModals.includes(name) ? [...state.shownModals, name] : state.shownModals
    }
  },
  [constants.CLEAR_MODAL_FROM_CONTEXT]: state => ({
    ...state,
    modalFromContext: ''
  }),
  [constants.TOGGLE_NAV]: (state, navState) => ({
    ...state,
    navOpen: navState
  }),
  [constants.SET_USER]: (state, user) => ({
    ...state,
    hasUser: true,
    user
  }),
  [constants.SET_PAGE_ERROR]: (state, pageError) => ({
    ...state,
    pageError
  }),
  [constants.LOAD_BROWSER]: (state, browser) => ({
    ...state,
    browser
  }),
  [constants.HANDLE_REFERRAL]: (state, referrer) => {
    if (!referrer) {
      return state
    }

    const localStorage = getLocalStorage()
    const referralConfig = CONFIG.referrals.find(referral => {
      const usedReferral = localStorage?.getItem(`used-referral-partner-${referral.partner}`)
      if (usedReferral && usedReferral !== 'false') {
        return false
      }

      for (const s of referral.includes) {
        if (referrer.includes(s) && localStorage) {
          // if referral partner is an unlimited first click partner than we don't want to set the localStorage
          if (referral.unlimitedFirstClicks) {
            return true
          }

          localStorage.setItem(`used-referral-partner-${referral.partner}`, 'true')
          return true
        }
      }

      return false
    }) || {}

    // Remove reprints link resolver
    // from referral partner because we now use
    // check availability.
    return {
      ...state,
      referralPartner: referralConfig.partner || null,
      freeReports: referralConfig.reports || 0,
      linkResolver: (referrer === 'reprintsdesk.com')
        ? null
        : {
            ...(referralConfig.linkResolver || state.linkResolver),
            referrerLocation: referrer
          }
    }
  },
  [constants.DOCK_FREE_REPORT]: state => ({
    ...state,
    freeReports: state.freeReports - 1
  }),
  [constants.LOAD_USER]: (state) => ({
    ...state,
    fetchingUser: true
  }),
  [constants.LOAD_USER_SUCCESS]: (state, user) => ({
    ...state,
    fetchingUser: false,
    user,
    hasUser: true
  }),
  [constants.LOAD_USER_FAILURE]: (state) => ({
    ...state,
    fetchingUser: false
  }),
  [constants.MERGE_USER_SUBSCRIPTION]: (state, subscription) => ({
    ...state,
    user: {
      ...(state.user || {}),
      subscription: {
        ...(state.user?.subscription || {}),
        plan: subscription.plan
      }
    }
  }),
  [constants.DELETE_USER]: (state) => ({
    ...state,
    fetchingUser: true
  }),
  [constants.DELETE_USER_SUCCESS]: (state, user) => ({
    ...state,
    fetchingUser: false,
    user: null
  }),
  [constants.DELETE_USER_FAILURE]: (state) => ({
    ...state,
    fetchingUser: false
  }),
  [constants.SAVE_USER]: (state) => ({
    ...state,
    savingUser: true,
    saveUserError: false
  }),
  [constants.SAVE_USER_SUCCESS]: (state, user) => ({
    ...state,
    savingUser: false,
    saveUserError: false,
    user
  }),
  [constants.SAVE_USER_FAILURE]: (state, error) => ({
    ...state,
    savingUser: false,
    saveUserError: true
  }),
  [constants.LOGOUT_SUCCESS]: (state, user) => ({
    ...state,
    user: null,
    hasUser: false
  }),
  [constants.GET_ENTITLEMENTS]: (state) => ({
    ...state,
    entitlementsLoading: true
  }),
  [constants.GET_ENTITLEMENTS_SUCCESS]: (state, payload) => ({
    ...state,
    entitlementsLoading: false,
    userEntitlements: payload
  }),
  [constants.GET_ENTITLEMENTS_FAILURE]: (state) => ({
    ...state,
    entitlementsLoading: false,
    userEntitlements: {}
  }),
  [constants.SAVE_REFERRALS]: (state, { referrals }) => ({
    ...state,
    referralsLoading: true,
    user: {
      ...state.user,
      referred: [...new Set([...state.user.referred, ...referrals])]
    },
    saveReferralsError: false
  }),
  [constants.SAVE_REFERRALS_SUCCESS]: (state, payload) => ({
    ...state,
    referralsLoading: false,
    user: {
      ...state.user,
      referred: payload && payload.referred
    },
    saveReferralsError: false
  }),
  [constants.SAVE_REFERRALS_FAILURE]: (state) => ({
    ...state,
    referralsLoading: false,
    saveReferralsError: true
  }),
  [constants.EXTENSION_LOADED]: state => ({
    ...state,
    extensionLoaded: true
  }),
  [constants.SET_BANNER_CONTENT]: (state, bannerContent) => ({
    ...state,
    bannerContent
  }),
  [constants.SET_BANNER_CLOSED]: (state, bannerClosed) => ({
    ...state,
    bannerClosed
  }),
  [constants.CHECK_PREMIUM_IP_SUCCESS]: (state, organizationLicense) => {
    if (organizationLicense.hasLicense && organizationLicense.linkResolverURL) {
      const linkResolver = {
        text: organizationLicense.linkResolverImageURL ? null : `Check for full-text from ${organizationLicense.name}`,
        baseURL: organizationLicense.linkResolverURL,
        partnerName: organizationLicense.slug,
        imageURL: organizationLicense.linkResolverImageURL
      }
      return {
        ...state,
        organizationLicense,
        linkResolver
      }
    }
    return ({
      ...state,
      organizationLicense
    })
  },
  [constants.CHECK_PREMIUM_IP_FAILURE]: state => ({
    ...state,
    organizationLicense: { hasLicense: false }
  }),
  [constants.TOGGLE_MOBILE_SEARCH]: state => ({
    ...state,
    showMobileSearch: !state.showMobileSearch
  }),
  [constants.REDEEM_CODE]: state => ({
    ...state,
    redeemCodeLoading: true,
    redeemCodeSuccess: false,
    redeemCodeError: null
  }),
  [constants.REDEEM_CODE_ERROR]: (state, payload, error) => ({
    ...state,
    redeemCodeLoading: false,
    redeemCodeSuccess: false,
    redeemCodeError: payload || error?.reponse?.data?.error || 'An error occurred please try again or contact us.'
  }),
  [constants.REDEEM_CODE_SUCCESS]: (state, payload) => ({
    ...state,
    redeemCodeLoading: false,
    redeemCodeSuccess: true,
    redeemCodeError: null,
    user: {
      ...state.user,
      subscription: {
        ...state.user.subscription,
        ...payload
      }
    }
  }),
  [constants.APPLY_DISCOUNT]: state => ({
    ...state,
    applyDiscountLoading: true,
    applyDiscountSuccess: false,
    applyDiscountError: null
  }),
  [constants.APPLY_DISCOUNT_ERROR]: (state, payload, error) => ({
    ...state,
    applyDiscountLoading: false,
    applyDiscountSuccess: false,
    applyDiscountError: payload || error?.reponse?.data?.error || 'An error occurred applying discount please try again or contact us support@scite.ai.'
  }),
  [constants.APPLY_DISCOUNT_SUCCESS]: (state) => ({
    ...state,
    applyDiscountLoading: false,
    applyDiscountSuccess: true,
    applyDiscountError: null
  }),
  [constants.FETCH_DISCOUNT]: state => ({
    ...state,
    userDiscount: null
  }),
  [constants.FETCH_DISCOUNT_SUCCESS]: (state, payload) => ({
    ...state,
    userDiscount: payload
  }),
  [constants.FETCH_DISCOUNT_ERROR]: state => ({
    ...state,
    userDiscount: null
  }),
  [constants.SET_HAS_USER]: (state, payload) => ({
    ...state,
    hasUser: payload
  }),
  [constants.SET_SEARCH_FOCUSED]: (state, focused) => ({
    ...state,
    search: {
      ...state.search,
      focused
    }
  }),
  [constants.SET_SEARCH_TERM]: (state, term) => ({
    ...state,
    search: {
      ...state.search,
      term: term || ''
    }
  }),
  [constants.FETCH_AUTOCOMPLETES]: (state, { field }) => ({
    ...state,
    search: {
      ...state.search,
      autocompletesLoadTracker: {
        ...state.search.autocompletesLoadTracker,
        [field]: {
          ...state.search.autocompletesLoadTracker[field],
          state: 'loading'
        }
      }
    }
  }),
  [constants.FETCH_AUTOCOMPLETES_SUCCESS]: (state, { field, autocompletes }) => {
    const nextAutocompletes = {
      ...state.search.autocompletes,
      [field]: autocompletes
    }
    const autocompletesLoadTracker = {
      ...state.search.autocompletesLoadTracker,
      [field]: {
        ...state.search.autocompletesLoadTracker[field],
        state: 'loaded'
      }
    }

    return {
      ...state,
      search: {
        ...state.search,
        autocompletes: nextAutocompletes,
        autocompletesLoadTracker
      }
    }
  },
  [constants.FLUSH_AUTOCOMPLETES]: (state, key) => ({
    ...state,
    search: {
      ...state.search,
      autocompletes: {
        ...state.search.autocompletes,
        [key]: []
      }
    }
  }),
  [searchConstants.HANDLE_QUERY_CHANGE]: (state, query) => ({
    ...state,
    search: {
      ...state.search,
      term: query.q || ''
    }
  }),
  [searchConstants.CLEAR_FILTERS]: (state, query) => ({
    ...state,
    search: {
      ...defaultState.search,
      term: state.search.term
    }
  }),
  [constants.FETCH_ONBOARDING]: (state) => ({
    ...state,
    onboardingLoadTracker: {
      state: 'loading'
    }
  }),
  [constants.FETCH_ONBOARDING_SUCCESS]: (state, onboarding) => ({
    ...state,
    onboardingLoadTracker: {
      state: 'success'
    },
    user: {
      ...state.user,
      onboarding
    }
  }),
  [constants.FETCH_ONBOARDING_ERROR]: (state) => ({
    ...state,
    onboardingLoadTracker: {
      state: 'error'
    }
  }),
  [constants.FLUSH_ONBOARDING_LOADED]: (state) => ({
    ...state,
    onboardingLoadTracker: {
      state: undefined
    }
  }),
  [constants.SET_REFERENCES_TO_EXPORT]: (state, dois) => ({
    ...state,
    referencesToExport: dois
  }),
  [constants.SET_CITE_GENERATOR_PAPER_DATA]: (state, { citeGeneratorPaperData }) => ({
    ...state,
    citeGeneratorPaperData: {
      ...citeGeneratorPaperData
    },
    referencesToExport: citeGeneratorPaperData ? [citeGeneratorPaperData.doi] : []
  }),
  [constants.UPDATE_DIMENSIONS]: (state, { name, dimensions }) => ({
    ...state,
    dimensions: {
      ...state.dimensions,
      [name]: dimensions
    }
  }),
  [constants.TRIAL_ACTIVATED]: (state) => ({
    ...state
  }),
  [constants.TRIAL_ACTIVATED_SUCCESS]: (state) => ({
    ...state,
    user: {
      ...state.user,
      activatedTrial: true
    }
  }),
  [constants.TRIAL_ACTIVATED_FAILURE]: (state) => ({
    ...state
  }),
  [constants.SET_COOKIE_PREFERENCE]: (state, cookiePreference) => ({
    ...state,
    cookiePreference
  }),
  [constants.FETCH_CHURN_KEY]: (state) => ({
    ...state,
    userChurnKey: null,
    churnKeyLoading: true
  }),
  [constants.FETCH_CHURN_KEY_SUCCESS]: (state, { churnKeyHash }) => ({
    ...state,
    userChurnKey: churnKeyHash,
    churnKeyLoading: false
  }),
  [constants.FETCH_CHURN_KEY_FAILURE]: (state) => ({
    ...state,
    userChurnKey: null,
    churnKeyLoading: false
  }),
  [constants.FETCH_FULL_TEXT_LINK]: (state, { doi }) => ({
    ...state,
    fullTextLinkState: addToFullTextLinkStateQueue(state.fullTextLinkState, { loading: true }, doi)
  }),
  [constants.FETCH_FULL_TEXT_LINK_SUCCESS]: (state, { doi, linkText, linkURL }) => ({
    ...state,
    fullTextLinkState: addToFullTextLinkStateQueue(state.fullTextLinkState, {
      loading: false, linkText, linkURL
    }, doi)
  }),
  [constants.FETCH_FULL_TEXT_LINK_FAILURE]: (state, { doi }) => ({
    ...state,
    fullTextLinkState: addToFullTextLinkStateQueue(state.fullTextLinkState, {
      loading: false,
      linkText: 'View full text',
      linkURL: 'https://doi.org/' + doi
    }, doi)
  }),
  [constants.FETCH_AG_CHECK_AVAILABILITY_DETAILS]: (state, { doi }) => ({
    ...state,
    addToAGAvailabilityState: addToAGAvailabilityStateQueue(state.agAvailabilityState, { loading: true }, doi)
  }),
  [constants.FETCH_AG_CHECK_AVAILABILITY_DETAILS_SUCCESS]: (state, { doi, ctaPurchasePriceUsd, ctaRentalPriceUsd, ctaDefaultCopy, ctaDefaultType, ctaDefaultLink }) => ({
    ...state,
    agAvailabilityState: addToAGAvailabilityStateQueue(state.agAvailabilityState, {
      loading: false,
      error: false,
      ctaPurchasePriceUsd,
      ctaRentalPriceUsd,
      ctaDefaultCopy,
      ctaDefaultType,
      ctaDefaultLink
    }, doi)
  }),
  [constants.FETCH_AG_CHECK_AVAILABILITY_DETAILS_FAILURE]: (state, { doi }) => ({
    ...state,
    agAvailabilityState: addToAGAvailabilityStateQueue(state.agAvailabilityState, {
      loading: false,
      error: true,
      ctaDefaultCopy: 'Access with Article Galaxy',
      ctaDefaultType: 'default',
      ctaDefaultLink: `https://www.reprintsdesk.com/landing/po.aspx?rft_id=info:doi/${doi}&utm_campaign=scite&utm_source=scite&utm_medium=referral`
    }, doi)
  }),
  [constants.UPDATE_ACCESS_LOG]: (state) => ({
    ...state
  }),
  [constants.FETCH_GEOLOCATION]: (state) => ({
    ...state,
    ipLocationData: {
      ...state.ipLocationData,
      error: false,
      loading: true
    }
  }),
  [constants.FETCH_GEOLOCATION_SUCCESS]: (state, data) => ({
    ...state,
    ipLocationData: {
      data: {
        ...data
      },
      error: false,
      loading: false
    }
  }),
  [constants.FETCH_GEOLOCATION_FAILURE]: (state) => ({
    ...state,
    ipLocationData: {
      data: {
        city: 'Chicago',
        country: 'United States',
        currency: 'USD',
        timezone: 'America/Chicago'
      },
      error: true,
      loading: false
    }
  })
}, saga)

export default ui
