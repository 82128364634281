import { Helmet } from 'react-helmet'

const titleCopy = 'AI for Research | Scite'
const descriptionCopy = 'Researchers around the world use Scite to better understand research, uncover debates, ensure they are citing reliable studies, and improve their writing.'

//
// MILO:
//       Generic <head/> we render on all pages, can be overridden/added to
//       easily further down the tree with the Helmet component
//
export const Head = () => (
  <Helmet>
    <meta name='viewport' content='width=device-width, initial-scale=1, maximum-scale=5, viewport-fit=cover' />

    {CONFIG.cdnURL && <link rel='preconnect' href={CONFIG.cdnURL} crossorigin />}
    <link rel='preconnect' href='https://fonts.googleapis.com' />
    <link rel='preconnect' href='https://fonts.gstatic.com' crossorigin />

    <title>{titleCopy}</title>
    {CONFIG.sciteFrontendURL === 'https://staging.scite.ai' && (
      <meta name='robots' content='noindex' />
    )}

    <meta property='og:site_name' content='scite.ai' />
    <meta property='og:type' content='website' />

    <meta property='title' content={titleCopy} />
    <meta property='og:title' content={titleCopy} />
    <meta property='twitter:title' content={titleCopy} />

    <meta name='description' content={descriptionCopy} />
    <meta property='og:description' content={descriptionCopy} />
    <meta property='twitter:description' content={descriptionCopy} />

    <meta property='og:image' content={`${CONFIG.assetsBaseURL}/images/scite-opengraph.jpg`} />
    <meta property='twitter:image' content={`${CONFIG.assetsBaseURL}/images/scite-opengraph.jpg`} />

    <meta property='og:url' content='https://scite.ai' />
    <meta property='twitter:card' content='summary_large_image' />

    <meta name='twitter:site' content='@scite' />

    <link rel='shortcut icon' href={`${CONFIG.assetsBaseURL}/images/favicon.ico`} />
    <link rel='stylesheet preload prefetch' as='style' href={`${CONFIG.assetsBaseURL}/css/line-awesome-font-awesome.min.css?v=2`} />

    {/* Main app font */}
    <link rel='preload' as='style' href='https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600&display=swap' />
    <link rel='stylesheet' as='style' href='https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600&display=swap' media='print' onload="this.media='all'" />
    <noscript>
      {'<link rel="stylesheet" as="style" href="https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@200;300;400;500;600&display=swap">'}
    </noscript>

    {/* Add WebSite interaction service for exposing rich search behaviour to search engines. */}
    <script type='application/ld+json'>
      {JSON.stringify({
        '@context': 'https://schema.org',
        '@type': 'WebSite',
        url: 'https://scite.ai',
        potentialAction: {
          '@type': 'SearchAction',
          target: 'https://scite.ai/search?q={search_term_string}',
          'query-input': 'required name=search_term_string'
        }
      })}
    </script>
  </Helmet>
)

export default Head
